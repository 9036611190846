/* eslint-disable react-hooks/rules-of-hooks */
import { useRecoilState } from 'recoil';
import {
  addressAtom,
  captchaAtom, codeAtom, errorAtom,
  loadingAtom,
  moreDetailsInputAtom,
  moreDetailsOutputAtom,
  userInfoAtom
} from '../store';
import { getByCode, getFinalResults, getInitialResults } from '../../../api';
import { SearchResponseType } from '../../../api/types';
import { assertUnreachable, wrapLoadable } from '../../../utils';
import { Fragment, useEffect, useRef } from 'react';
import { State as StateAddress } from '../pages/SearchAddress';
import { State as StateCode } from '../pages/SearchCode';

type IUseRequest<T> = () => { callback: () => Promise<T> }

const searchInit : IUseRequest<StateAddress> = () => {
  const [captcha, setCaptcha] = useRecoilState(captchaAtom);
  const [searchData] = useRecoilState(addressAtom);
  const [,setDetailsInput] = useRecoilState(moreDetailsInputAtom);
  const [,setUserInfo] = useRecoilState(userInfoAtom);
  const callback = async()  => {
    if (!searchData) throw new Error('Отсутствует SearchData!');
    const data = await getInitialResults(captcha, searchData);
    switch (data.type) {
      case SearchResponseType.MoreDetailsRequired:
        setCaptcha(data.payload.verification_token);
        setDetailsInput(data.payload);
        return StateAddress.MoreDetailsRequired;
      case SearchResponseType.SimpleAnswer:
        if (data.payload.length === 0) {
          return StateAddress.CardNotFound;
        }
        setUserInfo(data.payload);
        return StateAddress.CardSuccess;
      default:
        assertUnreachable(data, 'Неизвестный тип ответа!');
    }
  }
  return { callback };
}

const searchExtra : IUseRequest<StateAddress> = () => {
  const [captcha,] = useRecoilState(captchaAtom);
  const [detailsOutput] = useRecoilState(moreDetailsOutputAtom);
  const [,setUserInfo] = useRecoilState(userInfoAtom);
  const callback = async() => {
    if (!detailsOutput) throw new Error('Отсутствует MoreDetailsPayload!');
    const { payload } = await getFinalResults(captcha, detailsOutput);
    if (payload.length === 0) return StateAddress.CardNotFound;
    setUserInfo(payload);
    return StateAddress.CardSuccess;
  }
  return { callback }
}

const searchCode = () => {
  const [captcha] = useRecoilState(captchaAtom);
  const [code] = useRecoilState(codeAtom);
  const [,setUserInfo] = useRecoilState(userInfoAtom);

  const callback = async() => {
    const { type, payload } = await getByCode(captcha, code);
    if (type !== SearchResponseType.SimpleAnswer) throw new Error('Неожиданный тип ответа сервера!');
    if (payload.length === 0) {
      return StateCode.CardNotFound;
    }
    setUserInfo(payload);
    return StateCode.CardSuccess;
  }
  return { callback }
}

interface RequestProps<T> {
  target: IUseRequest<T>,
  onComplete: (state: T) => void,
  onError: (onRetry: () => void) => void
}

const Request = <T,>(props: RequestProps<T>) => {
  const [, setLoading] = useRecoilState(loadingAtom);
  const [, setError] = useRecoilState(errorAtom);
  const refMounted = useRef(false);
  const target = props.target();
  const loadData = async() => {
    try {
      const state = await wrapLoadable(target.callback, {setLoading, setError});
      props.onComplete.call(null, state);
    } catch (ex) {
      props.onError.call(null, onRetry);
    }
  }
  const onRetry = () => loadData();

  useEffect(() => {
    if (refMounted.current) return;
    refMounted.current = true;
    loadData().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.target]);

  return <Fragment />
}

export const requests = { searchExtra, searchInit, searchCode }

export default Request;
