
import x from './ProgressBar.module.scss';
import { cx } from '../utils';

interface ProgressBarProps {
  visible?: boolean
}

const ProgressBar = (props: ProgressBarProps) => {
  const { visible = true } = props;
  return <div className={cx(x.container, {[x.visible]: visible})}>
    <div className={x.progress} />
  </div>
}

export default ProgressBar;