import styles from './Header.module.scss';
import { Link } from "react-router-dom";
import { routes } from '../pages/routes';

const Header = () => {
  return <nav className={styles.navbar}>
    <Link className={styles.header} to="/">
      <img src="/intercom.png" alt="" />
      мой-домофон.рф
    </Link>
    <div className={styles.links}>
      <a className={styles.link}
         href="http://cdn1.abonent4.ru/MyDomofon/PayInstructions.pdf"
         rel="noreferrer" target="_blank">
        Инструкция</a>
      <Link className={styles.link} to={routes.searchBase.location}>Поиск</Link>
      <Link className={styles.link} to={routes.business.location}>Партнерам</Link>
    </div>
  </nav>;
}

export default Header;
