import { DependencyList, useEffect } from 'react';

export const useDocumentEvent = <K extends keyof DocumentEventMap>(
  event: K,
  handler: (ev: DocumentEventMap[K]) => any,
  deps?: DependencyList,
  options?: boolean | AddEventListenerOptions | undefined
) => {
  useEffect(() => {
    document.addEventListener(event, handler, options);
    return () => {
      document.removeEventListener(event, handler, options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export const useWindowEvent = <K extends keyof WindowEventMap>(
  event: K,
  handler: (ev: WindowEventMap[K]) => any,
  deps?: DependencyList,
  options?: boolean | AddEventListenerOptions | undefined
) => {
  useEffect(() => {
    window.addEventListener(event, handler, options);
    return () => {
      window.removeEventListener(event, handler, options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export const useElementEvent = (
  element: HTMLElement|Window,
  event: string,
  handler: EventListenerOrEventListenerObject,
  deps?: DependencyList,
  options?: boolean | AddEventListenerOptions | undefined
) => {
  useEffect(() => {
    element.addEventListener(event, handler, options);
    return () => {
      element.removeEventListener(event, handler, options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}