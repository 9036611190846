import x from '../index.module.scss';
import { createRef, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import ReCAPTCHA from "react-google-recaptcha";
import { captchaAtom, clientSettingsAtom } from '../store';

interface StepParameters {
  onSubmit?: () => void|Promise<unknown>,
  debug?: boolean,
}

const Wrapper = ({onCode, sitekey}: {
  onCode: (data: string) => void,
  sitekey: string
}) => {
  if (!sitekey) throw new Error('Sitekey for ReCaptcha is missing!');
  const refHolder = createRef<any>();
  return <div className={x.captcha_box}><ReCAPTCHA ref={refHolder}  sitekey={sitekey} onChange={onCode as any} /></div>
}

const StepCaptcha = (props: StepParameters) => {
  const [settings] = useRecoilState(clientSettingsAtom);
  const [captcha,setCaptcha] = useRecoilState(captchaAtom);

  const isDebugMode = settings && !settings?.captcha;
  const canSubmit = isDebugMode || !!captcha;

  useEffect(() => {
    setCaptcha(isDebugMode ? 'debug' : '');
  }, [isDebugMode]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = () => {
    if (!props.onSubmit) return;
    if (!captcha) {
      console.warn('Missing captcha code!');
      return;
    }
    props.onSubmit();
  }

  const onCode = (data: string) => {
    console.log('Recaptcha data', data);
    setCaptcha(data);
  }

  return <div>
    <div className={x.controlHolder}>
      <h4 className={"mx-auto"}>Защита от спама</h4>
    </div>

    {isDebugMode ? <div className="card card-body">
      <h3>ReCaptcha отключена в режиме отладки!</h3>
    </div> : <Wrapper onCode={onCode} sitekey={settings?.captcha!} />}

    <div className={x.controlHolder}>
      <button type="button"
              className="btn btn-primary mt-3 px-4 mx-auto"
              onClick={onSubmit}
              disabled={!canSubmit}>Продолжить</button>
    </div>

  </div>
}

export default StepCaptcha;
