import x from '../index.module.scss';
import InputAddress, { InputAddressRef } from '../../../components/InputAddress';
import SelectDropdown from '../../../components/SelectDropdown';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { getCities, getRegions, getStreets } from '../../../api';
import { Setter } from '../../../types';
import { useRecoilState } from 'recoil';
import { addressAtom } from '../store';

interface SMI_Props {
  onSubmit?: () => void,
}

const bindChange = (setter: Setter<string>) => (value: string) => setter(value);
const bindChangeInput = (setter: Setter<string>) => (ev: ChangeEvent<HTMLInputElement>) => setter(ev.target.value);

const __TEST_DATA = { region: 'Москва и московская область', city: 'Долгопрудный', street: 'Пацаева', house: '18' }
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useDebug = (callback: (data: typeof __TEST_DATA) => void) => {
  useEffect(() => {
    callback(__TEST_DATA);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

const useReset = (value: string|undefined, setter: Setter<string>, ref: React.RefObject<InputAddressRef>) => {
  useEffect(() => {
    setter(undefined);
    ref.current?.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ value ]);
}

const StepSearchAddress = (props: SMI_Props) => {

  const refCity = useRef<InputAddressRef>(null);
  const refStreet = useRef<InputAddressRef>(null);

  const [region, setRegion] = useState<string>();
  const [city, setCity] = useState<string>();
  const [street, setStreet] = useState<string>();
  const [house, setHouse] = useState<string>();
  const [,setSearchData] = useRecoilState(addressAtom);

  /*
  useDebug((data) => {
    setSearchData(data);
    props.onSubmit?.call(null);
  });
   */

  const onSubmit = () => {
    setSearchData({ region, city, street, house });
    props.onSubmit?.call(null);
  }
  const fullInput = [region,city,street,house];
  const canSubmit = !fullInput.every(x => !!x);

  const loadRegions = useCallback(() => getRegions(), []);
  const loadStreets = useCallback((search: string) => getStreets(city, search), [city]);
  const loadCity = useCallback((search: string) => getCities(region, search), [region]);

  useReset(region, setCity, refCity);
  useReset(city, setStreet, refStreet);

  return <form>
    {/*{JSON.stringify({region, city, street, house})}*/}
    <div className={x.controlHolder}>
      <h4 className={"mx-auto"}>Поиск под адресу:</h4>
    </div>
    <div className={x.controlHolder}>
      <div className={x.label}>Регион: </div>
      <SelectDropdown
        dataSource={loadRegions}
        emptyValue={'Выберите регион'}
        defaultValue={region}
        onChange={bindChange(setRegion)}
        disabled={false}/>
    </div>
    <div className={x.controlHolder}>
      <div className={x.label}>Город: </div>
      <InputAddress
        ref={refCity}
        searchDelegate={loadCity}
        defaultValue={city}
        onChange={setCity}
        disabled={!region} />
    </div>
    <div className={x.controlHolder}>
      <div className={x.label}>Улица: </div>
      <InputAddress
        ref={refStreet}
        searchDelegate={loadStreets}
        defaultValue={street}
        onChange={bindChange(setStreet)}
        disabled={!city} />
    </div>
    <div className={x.controlHolder}>
      <div className={x.label}>Дом: </div>
      <input type="text" className={"form-control " + x.control}
             defaultValue={house}
             onChange={bindChangeInput(setHouse)}
             disabled={!street} />
    </div>
    <div className={x.controlHolder}>
      <button type="button"
              className="btn btn-primary mt-3 px-4 mx-auto"
              onClick={onSubmit}
              disabled={canSubmit}>Искать по адресу</button>
    </div>
  </form>
}

export default StepSearchAddress;
