import { useRecoilState } from 'recoil';
import {
  errorAtom
} from '../store';
import { FC, Fragment, useEffect, useState } from 'react';
import { useTitle} from '../../../utils';
import StepSearchAddress from '../steps/StepSearchAddress';
import StepCaptcha from '../steps/StepCaptcha';
import StepMoreDetails from '../steps/StepMoreDetails';
import StepSuccess from '../steps/StepSuccess';
import StepNotFound from '../steps/StepNotFound';
import Request, { requests } from '../steps/StepRequest';
import StepTryAgain from '../steps/StepTryAgain';

export enum State {
  Initialize,
  Captcha,
  Request_SearchInitial,
  MoreDetailsRequired,
  Request_SearchExtra,
  CardSuccess,
  CardNotFound
}

const SearchAddress : FC = () => {

  useTitle('Поиск по адресу');

  const [,setError] = useRecoilState(errorAtom);
  const [state, setState] = useState<State>(State.Initialize);
  const [body, setBody] = useState<JSX.Element>();

  const toggle = (newState: State) => () => setState(newState);
  const onError = (onRetry: () => void) => setBody(<StepTryAgain onRetry={onRetry} onReset={toggle(State.Initialize)} />);

  useEffect(() => {
    if (state === State.Initialize) setError(undefined);
    const stateMap : Record<State, () => JSX.Element> = {
      [State.Initialize]: () => <StepSearchAddress onSubmit={toggle(State.Captcha)} />,
      [State.Captcha]: () => <StepCaptcha onSubmit={toggle(State.Request_SearchInitial)} debug={true} />,
      [State.Request_SearchInitial]: () => <Request target={requests.searchInit} onComplete={setState} onError={onError} />,
      [State.MoreDetailsRequired]: () => <StepMoreDetails onSubmit={toggle(State.Request_SearchExtra)} />,
      [State.Request_SearchExtra]: () => <Request target={requests.searchExtra} onComplete={setState} onError={onError} />,
      [State.CardSuccess]: () => <StepSuccess onSearchAgain={toggle(State.Initialize)} />,
      [State.CardNotFound]: () => <StepNotFound onSearchAgain={toggle(State.Initialize)} />
    }
    setBody(stateMap[state]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return <Fragment>{body}</Fragment>;
}

export default SearchAddress;
