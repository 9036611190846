import { SearchEntry } from '../types';
import {
  ISettings,
  MoreDetailsOutput,
  SearchAddressData,
  SearchRequestType,
  SearchResponse,
  SearchResponse_Simple, SubCompanyInfo
} from './types';
import axios from 'axios';
import { FullUserInfo } from './types';
import { convertFullUserInfo } from './converters';
import { keyof } from 'ts-keyof';

export enum AnalyticType {
  OpenCard = 1,
  Print = 2,
  QR = 3
}

const API_DEBUG = false;
const URL_PREFIX = API_DEBUG ? '/api/debug' : '/api';

export const sendAnalytic = (type: AnalyticType, token: string) => {
  const strName = keyof({AnalyticType}) + '.' + AnalyticType[type];
  const params = new URLSearchParams({ type, token } as any).toString();
  console.log('sendAnalytic', strName, token);
  navigator.sendBeacon(`${URL_PREFIX}/Analytic/Send?${params}`);
}

export const getClientSettings = () : Promise<ISettings> =>
  axios.get(`${URL_PREFIX}/settings`).then(x => x.data);

export const getRegions = () : Promise<Record<string,string>> =>
  axios.get(`${URL_PREFIX}/search/getRegions`).then(x => x.data);

export const getCities = async(region: string|undefined, search: string) : Promise<SearchEntry[]> =>
  axios.get(`${URL_PREFIX}/search/getCities`, { params: { region, search }}).then(x => x.data);

export const getStreets = async (city: string|undefined, search: string) : Promise<SearchEntry[]> =>
  axios.get(`${URL_PREFIX}/search/getStreets`, { params: { city, search }}).then(x => x.data);

export const getInitialResults = async(captcha: string, payload: SearchAddressData) : Promise<SearchResponse> =>
  axios.get<SearchResponse>(`${URL_PREFIX}/search/getInitialResults`, {
    params: {
      captcha,
      type: SearchRequestType.InitialData,
      ...payload
    }
  }).then(x => x.data);

export const getFinalResults = async(verification_token: string, payload: MoreDetailsOutput)
  : Promise<SearchResponse_Simple> =>
  axios.get<SearchResponse_Simple>(`${URL_PREFIX}/search/getFinalResults`, {
    params: {
      type: SearchRequestType.ExtraDetails,
      token: verification_token,
      ...payload
    }
  }).then(x => x.data);

export const getByCode = async(captcha: string, code: string) : Promise<SearchResponse_Simple> =>
  axios.get<SearchResponse_Simple>(`${URL_PREFIX}/search/getByCode`, {
    params: {
      captcha,
      code
    }
  }).then(x => x.data);

export const loadFirm = async (uuid: string) : Promise<FullUserInfo> =>
  axios<FullUserInfo>({
    url: `${URL_PREFIX}/show/user`,
    params: { uuid },
  }).then(x => x.data).then(x => convertFullUserInfo(x));

export const getFirmByToken = async(url: string) => {
  type Response = { branches: SubCompanyInfo[] } | { message: string };
  return axios.get<Response>(`${URL_PREFIX}/Firm/getByUrl`, { params: { url }}).then(x => x.data);
}
