import x from '../index.module.scss';
import { generatePath, useNavigate } from 'react-router-dom';
import { routes } from '../../routes';
import { useRecoilState } from 'recoil';
import { userInfoAtom } from '../store';
import { useState } from 'react';
import { ShortUserInfo } from '../../../api/types';
import { cx } from '../../../utils';
import { ShowURLs } from '../../Show';

interface SingleItemProps {
  onSearchAgain: () => void,
  openNewTab?: boolean
  info: ShortUserInfo
}

const SingleItem = (props: SingleItemProps) => {
  const { info, onSearchAgain } = props;

  const navigate = useNavigate();
  const onShowPaymentDetails = () => {
    const url = generatePath(routes.show.location, { page: ShowURLs.user, id: encodeURIComponent(info.permanent_id) })
    if (props.openNewTab) window.open(url, '_blank');
    else navigate(url);
  };

  const sum = parseFloat(props.info.sum ?? "0") || 0;

  return <div>
    <table className="table table-bordered border-primary">
      <tbody>
      <tr>
        <th>Организация</th>
        <td>{info.company_title}</td>
      </tr>
      <tr>
        <th>Адрес</th>
        <td>{info.address}</td>
      </tr>
      <tr>
        <th>Лицевой&nbsp;счёт&nbsp;&nbsp;&nbsp;</th>
        <td>{info.client_id}</td>
      </tr>
      {sum > 0 && <tr>
          <th>Долг: </th>
          <td className="fw-bold text-danger">{sum} руб.</td>
      </tr>}
      {sum < 0 && <tr>
          <th>Переплата: </th>
          <td className="fw-bold text-success">{Math.abs(sum)} руб.</td>
      </tr>}
      </tbody>
    </table>
    <div className={x.buttons}>
      <div className="btn btn-warning me-3" onClick={onSearchAgain}>
        <div>Искать ещё</div>
      </div>
      <div className="btn btn-success" onClick={onShowPaymentDetails}>
        <div>Подробности</div>
        <span>оплата квитанции</span>
      </div>
    </div>
  </div>
}

interface CardSuccessProps {
  onSearchAgain?: () => void,
}

const StepSuccess = (props: CardSuccessProps) => {
  const [globalIndex, setIndex] = useState(0);
  const [infos] = useRecoilState(userInfoAtom);
  const onSearchAgain = () => props.onSearchAgain?.call(null);
  const multiMode = infos.length > 1;
  return <div>
    {multiMode && <ul className={"nav nav-pills " + x.pills}>
      {infos.map((_, index) => <li key={index} className="nav-item">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className={cx("nav-link", {"active": globalIndex === index})}
           onClick={(ev) => {ev.preventDefault(); setIndex(index)}}
           href="src/pages/Search/steps/StepSuccess#">
          Фирма {index+1}
        </a>
      </li>)}
    </ul>}
    <SingleItem onSearchAgain={onSearchAgain} info={infos[globalIndex]} openNewTab={multiMode} />
  </div>
}

export default StepSuccess;
