import { BankInfo, FullUserInfo, SubCompanyInfo } from './types';

export const convertFullUserInfo = (input: any) : FullUserInfo => {
  type ShortBank = Omit<BankInfo, 'qr_url'>;
  interface IResponse {
    entity: {
      abonent_id: string,
      full_address: string,
      payment_range: string,
      tarif: string,
      payed: string,
      nachis: string,
      debt: string
      overpayed: string,
      to_pay: string,
      dsk_sum: string,
      abonent_ls: string[]
    },
    banks_qr: Record<string, string>,
    company: SubCompanyInfo & {
      banks: ShortBank[]
    },
    print_frame?: string,
    files?: Record<string, string>
  }
  const x = input as IResponse;
  const qrs = Object.values(x.banks_qr);
  const banks : BankInfo[] = x.company.banks.map((bank, index) => ({...bank, qr_url: qrs[index]}));
  return {
    uuid: x.entity.abonent_id,
    account_id: x.entity.abonent_id,
    abonent_ls: x.entity.abonent_ls,
    address: x.entity.full_address,
    billing_period: x.entity.payment_range,
    prices: {
      tarif: x.entity.tarif,
      payed: x.entity.payed,
      charges: x.entity.nachis,
      debt: x.entity.debt,
      overpay: x.entity.overpayed,
      to_pay: x.entity.to_pay,
      raw_sum: x.entity.dsk_sum
    },
    company: x.company,
    banks,
    print_frame: x.print_frame,
    files: x.files
  }
}
