import x from '../index.module.scss';
import { FC, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { codeAtom } from '../store';

interface SMI_Props {
  onSubmit?: () => void,
}

const StepSearchCode : FC<SMI_Props> = (props) => {
  const [code, setCode] = useState<string>('');
  const [, setOutput] = useRecoilState(codeAtom);
  const canSubmit = useMemo(() => code.length === 0, [code]);

  const onSubmit = () => {
    setOutput(code);
    props.onSubmit?.call(null);
  }

  return <div>
    <div className={x.controlHolder}>
      <h4 className={"mx-auto"}>Поиск по лицевому счёту:</h4>
    </div>
    <div className={x.controlHolder}>
      <div className={x.label}>Л/С: </div>
      <input type="text" className={"form-control " + x.control}
             defaultValue={code}
             onChange={(ev) => setCode(ev.target.value)}
             disabled={false} />
    </div>
    <div className={x.controlHolder}>
      <button type="button"
              className="btn btn-primary mt-3 px-4 mx-auto"
              onClick={onSubmit}
              disabled={canSubmit}>Искать по лицевому счёту</button>
    </div>
  </div>
}

export default StepSearchCode;
