import x from './root.module.scss';
import { useEffect, useState } from 'react';
import Checkbox from '../../components/Checkbox';
import { cx} from '../../utils';
import { getRenderTarget } from './utils';

const useDOMClassEffect = (element: HTMLElement, className: string) => {
  useEffect(() => {
    element.classList.add(className);
    return () => element.classList.remove(className);
  });
}

const Print = () => {

  const [grayscale, setGrayscale] = useState(false);
  const [loading] = useState(false);
  const [body, setBody] = useState<JSX.Element>();
  useDOMClassEffect(document.documentElement, x.html);
  useDOMClassEffect(document.body, x.body);

  useEffect(() => {
    setBody(getRenderTarget());
  }, []);

  return <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
    <div className={x.navbar}>
      <button type="button"
              className={cx("btn","btn-success", x.button)}
              disabled={loading}
              onClick={() => window.print()}>
        Распечатать документ
      </button>
      <Checkbox className="mt-2" title={"Черно-белый документ"} onChange={setGrayscale} />
    </div>
    <div className={cx(x.page, {[x.grayscale]: grayscale})}>
      <div className={cx(x.page_content)}>
        {body}
      </div>
      {loading && <div className={x.overlay}>
        <div className={x.block}>
          <div className="progress">
            <div className="progress-bar progress-bar-striped progress-bar-animated" style={{"width": "100%"}} />
          </div>
          <h3 className="text-center mt-2">Подготовка документа к печати...</h3>
        </div>
      </div>}
    </div>

  </div>
}

export default Print;
