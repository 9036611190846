import { RecoilState, useRecoilState } from 'recoil';
import { Fragment, useMemo } from 'react';
import { AxiosError } from 'axios';

interface ErrorAlertProps {
  state: RecoilState<unknown>
  icon?: boolean
  dismissible?: boolean
}

const ErrorAlert = (props: ErrorAlertProps) => {
  const [rawError, setError] = useRecoilState(props.state);
  const error = useMemo<JSX.Element|string|undefined>(() => {
    if (rawError == null || typeof rawError === 'undefined') return undefined;
    if (typeof rawError === 'string') return rawError;
    if (rawError instanceof AxiosError) {
      const body = rawError?.response?.data?.message;
      if (body) return <strong>{body}</strong>;
    }
    if (rawError instanceof Error) return <div>
      <strong>{rawError.name}: </strong>
      {rawError.message}
    </div>
    return JSON.stringify(rawError);
  }, [rawError]);

  if (rawError === undefined) return <Fragment />;

  return <div className="alert alert-danger alert-dismissible">
    {props.dismissible && <button type="button" className="btn-close" onClick={() => setError(undefined)} />}
    <div>
      {error}
    </div>
  </div>
}

export default ErrorAlert;
