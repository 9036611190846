import { Link } from 'react-router-dom';
import { useTitle } from '../utils';

const Landing = () => {

  useTitle(`мой-домофон.рф - Главная`);

  return <div>
    <div>
      <h3 className="mt-3 mb-3">О сайте</h3>

      <div className="alert alert-warning alert-rounded alert-border">
        <span className="fw-bold">Внимание! </span>
        Сайт является посредником между пользователем и домофонной организацией.
        <br/>
        Вся ответственность за некорректные данные лежит на обслуживающих вас управляющих компаниях.
      </div>

      <div style={{whiteSpace: 'pre-line'}}>{`Данный сервис позволяет по адресу узнать задолженность за домофон, антенну.
Указав свой адрес вы получите квитанцию для оплаты домофона, антенны.
Также задолженность за домофон, антенну можно узнать по телефонам, указанным в квитанции на оплату.
Эти же телефоны указаны на самой квитанции.`}</div>
      <div style={{whiteSpace: 'pre-line'}}>{`Также наш сервис поможет вам узнать прошла ли оплата за домофон/антенну.
Если вы оплатили за домофон/антенну, то через несколько дней вы снова сможете посмотреть задолженность за домофон/антенну по своему адресу, т.к. данные по оплате на сайте обновляются примерно один раз в неделю. 
Более подробную информацию по оплатам, поступившим на ваш лицевой счет, вы сможете узнать по телефонам указаных в квитанции.`}</div>

      <div className="mb-3 mt-3">
        Данный сайт позволяет пользователем получить полезную информацию об их управляющей компании.
        <br/>
        Вы управляющая компания и хотите подключиться или предложить идею? <Link to="/business">Напишите нам!</Link>
      </div>
    </div>

    <div>
      <h3 className="mb-4">Возможности</h3>

      <div className="features">
        <a className="feature-card" href="/поиск">
          <img src="/images/map.png" alt="" width={64} height={64} />
          <div className="ms-4">
            <h5>Поиск по адресу</h5>
            Найти домофонную организацию по указанному адресу
          </div>
        </a>
        <a className="feature-card" href="/поиск">
          <img src="/images/debt.png" alt="" width={64} height={64} />
          <div className="ms-4">
            <h5>Проверить задолженность</h5>
            Узнать свою задолженность перед домофонной организацией
          </div>
        </a>
        <a className="feature-card" href="/поиск">
          <img src="/images/pay.png" alt="" width={64} height={64} />
          <div className="ms-4">
            <h5>Платежные реквизиты</h5>
            Узнать платежные реквизиты своей домофонной организации
          </div>
        </a>
      </div>
    </div>

    <footer className="footer py-3">
      <div className="container">
        <span className="text-muted">
          На базе ИПРС (Информационно-Поисковой Рассчётной Системы)
          <span> </span>
          <a href="https://abonent4.ru">Абонент 4</a>.
          <br/>
          {new Date().getFullYear()} год
        </span>
      </div>
    </footer>

  </div>
}

export default Landing;
