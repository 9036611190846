import { FullUserInfo } from '../api/types';
import { Fragment } from 'react';

const ToPaySum = ({card} : {card: FullUserInfo}) => {
  const toPay = card.prices.to_pay;
  if (toPay) return <Fragment>{toPay}</Fragment>;
  const sum = card.prices.raw_sum?.replace('-', '');
  return <span>
    <span style={{fontWeight: "bold", color: "darkgreen"}}>ПЕРЕПЛАТА </span>
    в {sum} руб.
    (оплачивать не нужно)
  </span>
}

export default ToPaySum;
