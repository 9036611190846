import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { loadingAtom } from './store';
import CardFirm from './CardFirm';

const Loader = ({title} : { title?: string}) => {
  return <div>
    <div className="progress">
      <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
           style={{"width": "100%"}}/>
    </div>
    <h5 className="text-center mt-3">{title}</h5>
  </div>;
};

export const ShowURLs = {
  user: 'абонент'
}

const Show = () => {

  const params = useParams();
  const [body, setBody] = useState<JSX.Element>(<h3 className="">Нет информации для отображения...</h3>)
  const [loading] = useRecoilState(loadingAtom);

  useEffect(() => {
    const { page, id } = params;
    if (!page) throw new Error('Missing page!');
    if (!id) throw new Error('Missing ID!');
    switch (page) {
      case ShowURLs.user:
        setBody(<CardFirm uuid={id} />);
        break;
      default:
        throw new Error(`Unknown page: ${page}`);
    }
  }, [params]);

  return <div className="mx-auto" style={{"maxWidth": "800px"}}>
    <div className="card">
      <div className="card-body pt-4">
        {loading && <Loader title="Загрузка информации..."  />}
        {body}

        <div className="mt-4 d-flex justify-content-center">
          {/*<div className="btn btn-warning" onClick={() => navigate(-1)}>Вернуться назад</div>*/}
        </div>
      </div>
    </div>
  </div>;
}

export default Show;
