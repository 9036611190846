
import x from './UserPayment.module.scss';
import { FC } from 'react';
import { cx, useTitle } from '../../utils';
import { FullUserInfo } from '../../api/types';
import ToPaySum from '../../components/ToPaySum';

interface Props {
  card: FullUserInfo,
  selectedBank?: number
}

const entry = (key: string, value: string) => {
  return <div>
    <span className="me-2">{key}:</span>
    <span className={x.text_1 + " fw-bold"}>{value}</span>
  </div>;
}

type TableEntry = [string|JSX.Element,string|JSX.Element];
interface TableParams {
  noBorders?: boolean
  classTable?: string,
  classValue?: string
  classTitle?: string,
  prefixRow?: string|JSX.Element,
  titleWidth?: string
}

const Table = (rows: TableEntry[], params: TableParams = {}) => {
  return <table className={params.classTable}>
    <colgroup>
      <col style={{"width": params.titleWidth}} />
      <col />
    </colgroup>
    <tbody>
      {params.prefixRow && <tr>{params.prefixRow}</tr>}
      {rows.map((row, key) => <tr key={key}>
        <td className={params.classTitle}>{row[0]}{params.noBorders ? ":" : ""}</td>
        <td className={params.classValue}>{row[1]}</td>
      </tr>)}
    </tbody>
  </table>
}

const UserPayment : FC<Props> = (props) => {

  useTitle(`Печать->Извещение-квитанция`);

  const { card, selectedBank=0 } = props;
  const bank = card.banks[selectedBank];

  const root = <div className={x.root}>
    <div className={x.card}>

      <div className={x.left_block}>
        {card.company.logo && <div className={x.logo_block}>
            <img src={card.company.logo} alt="" />
        </div>}

        <h5 className={"fw-bold " + x.formName}>ИЗВЕЩЕНИЕ-КВИТАНЦИЯ</h5>
        <div className={x.image_qr}><img src={bank.qr_url} alt="" /></div>
      </div>

      <div className={cx("fw-bold", x.text_1)}>
        {card.company.payment_name}
      </div>
      <div className={"fw-bold " + x.text_1}>
        <span>{card.company.phone}</span>
        <span className="ms-3">{card.company.email}</span>
      </div>
      <div className="border-top border-bottom border-dark mt-1 mb-1 row">
        <div className="col-6">
          {entry('Р. счёт', bank.rs)}
          {entry('Банк', bank.name)}
          {entry('К. счёт', bank.ks)}
        </div>
        <div className="col-6">
          &nbsp;
          {entry('ИНН', card.company.inn)}
          {entry('КПП', card.company.kpp)}
          {entry('БИК', bank.bik)}
        </div>
      </div>
      {Table([
        ['Лицевой счёт', card.abonent_ls.join(', ')],
        ['Адрес плательщика', card.address],
        ['Расчётный период', card.billing_period],
        ['Ежемесячный тариф', card.prices.tarif],
      ], {classTitle: "text-right pe-3", classValue: x.text_2 + " fw-bold", titleWidth: '180px'})}
      {Table([
        ['Наименование платежа', 'Оплата технического обслуживания домофона '],
        ['Оплачено за расчётный период', card.prices.payed],
        ['Начислено за расчётный период', card.prices.charges],
        ['Долг на начало периода', card.prices.debt],
        ['Переплата на начало периода', card.prices.overpay ?? ' - '],
        [<span className="fw-bold">Итого к оплате</span>, <ToPaySum card={card} />],
      ], {classTable: "mt-3 " + x.table_1, titleWidth: '230px'})}
      <div className="mt-4 px-4">
        плательщик _______________________
      </div>
    </div>
  </div>
  return <div className={x.holder}>
    {root}
    {card.print_frame &&
        <iframe src={card.print_frame} sandbox="allow-scripts" title="..." className={x.frame}></iframe>}
  </div>
}

export default UserPayment;
