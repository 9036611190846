import { cx } from '../utils';


export interface IAlertProps {
  type: 'success'|'danger'|'warning',
  message: string|JSX.Element
}

export const Alert = (props: IAlertProps) => {
  return <div className={cx('alert', `alert-${props.type}`)}>
    {props.message}
  </div>;
}
