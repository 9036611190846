import x from './SelectDropdown.module.scss';
import { ChangeEvent, useState } from 'react';
import { CancelPredicate, cx, useAsyncEffect, useCancellable } from '../utils';
import { StringRecord } from '../types';

interface DropdownProps {
  dataSource?: () => Promise<StringRecord>,
  onChange?: (data: string) => void,
  disabled?: boolean,
  defaultValue?: string,
  emptyValue?: string,
  className?: string
}

const DEFAULT_NAME = '__DEFAULT_VALUE__';

const SelectDropdown = (props: DropdownProps) => {
  const { emptyValue, disabled, defaultValue = DEFAULT_NAME } = props;
  const onChange = (ev: ChangeEvent<HTMLSelectElement>) => props.onChange?.call(null, ev.target.value);
  const [ loading, setLoading ] = useState(false);
  const [ data, setData ] = useState<StringRecord>({});
  const onInitialized = useCancellable(async (cancel: CancelPredicate) => {
    try {
      setLoading(true);
      if (!props.dataSource) return;
      const data = await props.dataSource();
      setData(data);
    } catch (ex) {
      console.error('Exception inside "Dropdown"!', ex);
    } finally {
      if (!cancel()) setLoading(false);
    }
  });
  useAsyncEffect(onInitialized, [props.dataSource]);

  return <div className={cx(x.root, {[x.root_loading]: loading})}>
    <select
      className={cx("form-select", x.select, props.className ?? "")}
      defaultValue={defaultValue}
      onChange={onChange}
      autoComplete="off"
      disabled={disabled}>
      {emptyValue &&
          <option value={DEFAULT_NAME} disabled={false}>{emptyValue}</option>}
      {Object.entries(data).map(([key,value]) => <option key={key} value={key}>{value}</option>)}
    </select>
    <div className={x.overlay}>
      <div className={x.progressHolder}>
        <div className={x.progress} />
      </div>
    </div>
  </div>
}

export default SelectDropdown;
