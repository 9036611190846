import x from './InputAddress.module.scss';
import React, { ChangeEvent, createRef, RefObject, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { debounce } from 'debounce';
import { before, CancelPredicate, cx, useCancellable, useDropdownClick } from '../utils';
import ProgressBar from './ProgressBar'
import { SearchEntry } from '../types';

interface InputAddressProps {
  onChange?: (id: string) => void,
  searchDelegate?: (search: string) => Promise<SearchEntry[]>,
  disabled?: boolean,
  defaultValue?: string,
  minLength?: number
}

export interface InputAddressRef {
  reset: () => void
}

const messages = {
  notFound: 'Ничего не найдено по данному запросу...',
  minLength: 'Для поиска нужно больше %x символов!',
};

/*
Открытие компонента:
1. Было введено несколько символов
2. Фокус находится внутри элемента
Закрытие компонента:
1. Был сделан выбор
2. Пользователь кликнул за пределами окна
3. Пользователь нажал Escape

Состояния компонента:
1. Идёт поиск результатов
2. Были найдены результаты
*/

// TODO: Переписать этот компонент
const InputAddress = React.forwardRef<InputAddressRef,InputAddressProps>(
  (props, ref) => {

  const [error] = useState(false);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [message, setMessage] = useState('');
  const [searchResult, setSearchResult ] = useState<SearchEntry[]>([]);
  const [ refRoot, opened, setOpened ] = useDropdownClick({ doNotOpen: () => searchResult.length === 0});
  const refInput = createRef<HTMLInputElement>();
  const refSelected = useRef<SearchEntry>();
  const searchDelegate = props.searchDelegate ?? (() => Promise.resolve([]));
  const { minLength = 2 } = props;
  const { disabled } = props;

  const reset = (completed? : boolean) => {
    setOpened(false);
    if (typeof completed === 'boolean') setCompleted(completed);
    setSearchResult([]);
  }

  useEffect(() => {
    if (typeof props.defaultValue === 'string') return;
    reset(false);
    if (refInput.current) refInput.current.value = '';
    refSelected.current = undefined;
    // eslint-disable-next-line
  }, [props.defaultValue]);

  useEffect(() => {
    if (opened || !refSelected.current || !refInput.current) return;
    refInput.current.value = refSelected.current.title;
    setCompleted(true);
  }, [opened]);

  useImperativeHandle(ref, () => ({
    reset
  }));

  const onChoice = (entry: SearchEntry) => (ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault(); // Убираем переход на #
    ev.stopPropagation(); // Прерываем обработку, чтобы не сработал useDropdownClick
    reset(true);
    props?.onChange?.call(null, entry.id);
    if (refInput.current) refInput.current.value = entry.title;
    refSelected.current = entry;
  }

  let onTyping = useCancellable(async (cancel: CancelPredicate, ev: ChangeEvent) => {
    const search = (ev.target as HTMLInputElement).value;
    if (search.length < minLength) {
      setMessage(messages.minLength.replace('%x', minLength.toString()));
      return;
    }
    setCompleted(false);
    setLoading(true);
    setSearchResult([]);
    let isCancel = false;
    try {
      const result = await searchDelegate(search);
      isCancel = cancel();
      if (isCancel) return;
      if (result.length === 0) setMessage(messages.notFound);
      setSearchResult(result);
    } finally {
      if (!isCancel) setLoading(false);
    }
  });
  onTyping = debounce(onTyping, 700);
  onTyping = before(() => setOpened(true), onTyping);

  const onKeyUp = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    switch (ev.key) {
      case 'Escape':
        reset(refSelected.current !== undefined);
        refInput.current?.blur();
        break;
    }
  }

  return <div className={
    cx("w-100", x.container, {
      [x.container_opened]: opened,
      [x.container_invalid]: error,
      [x.container_valid]: completed,
    })}
                    ref={refRoot as RefObject<HTMLDivElement>}>
    <input type="text"
           ref={refInput}
           autoComplete={''}
           className={cx({"invalid": error}, x.control)}
           defaultValue={props.defaultValue ?? ''}
           disabled={disabled}
           onKeyUp={onKeyUp}
           onChange={onTyping} />

    <div className={x.dropdown}>
      <ProgressBar visible={loading} />
      <div className={x.links}>
        {!loading && searchResult.length === 0 && <div className="px-3 py-2">
          {message}
        </div>}
        {searchResult.map((street, index) => <div key={index} className={x.link}>
          <div
             onClick={onChoice(street)}>
            {street?.type}
            &nbsp;
            <span className="fw-bold">{street.title}</span>
          </div>
          {street.description && <div style={{fontSize: '80%', color: '#666'}}>({street?.description})</div>}
        </div>)}
      </div>
    </div>

  </div>
});

const Memoized = React.memo(InputAddress);

export default Memoized;
