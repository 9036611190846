import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './kislota.scss';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const IS_DEBUG = true;
const Wrapper = IS_DEBUG ? React.StrictMode : Fragment;

root.render(
    <Wrapper>
      <App />
    </Wrapper>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
