import x from '../index.module.scss';


interface StepNotFoundProps {
  onSearchAgain?: () => void,
}

const StepNotFound = (props: StepNotFoundProps) => {
  const { onSearchAgain } = props;
  return <div>
    <div className={x.controlHolder}>
      <h4 className={"mx-auto"}>Ничего не найдено</h4>
    </div>
    <div className="text-center mt-2 mb-4">По вашему запросу не найдено ничего :(</div>
    <div className="mt-3 d-flex justify-content-center">
      <div className="btn btn-primary me-3" onClick={onSearchAgain}>Искать ещё</div>
    </div>
  </div>
}

export default StepNotFound;
