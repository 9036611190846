import { cx } from '../utils';
import React from 'react';


interface Props {
  items: (string|JSX.Element)[],
  selected?: number,
  onSelection?: (index: number) => void,
  type?: 'pills'
}

export const TabGroup = (props: Props) => {
  const { items, selected } = props;
  const type = 'nav-' + (props.type ?? 'pills');

  const bindClick = (index: number) => (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault();
    props.onSelection?.(index);
  }

  return <ul className={cx("nav mb-3", type)}>
    {items.map((title, index) => (
      <li className="nav-item" key={index}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" className={cx("nav-link", {active: index === selected})}
           onClick={bindClick(index)}
        >{title}</a>
      </li>
    ))}
  </ul>
}
