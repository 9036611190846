import x from '../index.module.scss';
import SelectDropdown from '../../../components/SelectDropdown';
import { ChangeEvent, useState } from 'react';
import { Setter, StringRecord } from '../../../types';
import { useRecoilState } from 'recoil';
import { moreDetailsOutputAtom, moreDetailsInputAtom } from '../store';

const bindChange = (setter: Setter<string>) => (value: string) => setter(value);
const bindChangeInput = (setter: Setter<string>) => (ev: ChangeEvent<HTMLInputElement>) => setter(ev.target.value);

interface SMD_Props {
  onSubmit?: () => void,
}

const getData = (source?: StringRecord) => () => Promise.resolve<StringRecord>(source ?? {});

const StepMoreDetails = (props: SMD_Props) => {

  const [korpus, setKorpus] = useState<string>();
  const [stroenie, setStroenie] = useState<string>();
  const [kvartira, setKvartira] = useState<string>();
  const [extraData] = useRecoilState(moreDetailsInputAtom);
  const [,setExtraOutput] = useRecoilState(moreDetailsOutputAtom);

  const fields = {
    korpus: extraData?.korpus && Object.keys(extraData.korpus).length > 0,
    stroenie: !!extraData?.stroenie && Object.keys(extraData.stroenie).length > 0,
    kvartira: !!extraData?.kvartira
  }

  const onSubmit = () => {
    setExtraOutput({ korpus, stroenie, kvartira });
    props.onSubmit?.call(null);
  }
  const canSubmit : boolean = (() => {
    if (fields.korpus && !korpus) return false;
    if (fields.stroenie && !stroenie) return false;
    if (fields.kvartira && !kvartira) return false;
    return true;
  })();

  return <div>
    <div className={x.controlHolder}>
      <h4 className={"mx-auto"}>Уточните детали:</h4>
    </div>
    {fields.korpus && <div className={x.controlHolder}>
      <div className={x.label}>Корпус: </div>
      <SelectDropdown
        dataSource={getData(extraData?.korpus)}
        emptyValue={'Выберите корпус'}
        defaultValue={korpus}
        onChange={bindChange(setKorpus)}
        disabled={false}/>
    </div>}
    {fields.stroenie && <div className={x.controlHolder}>
      <div className={x.label}>Строение: </div>
      <SelectDropdown
        dataSource={getData(extraData?.stroenie)}
        emptyValue={'Выберите строение'}
        defaultValue={stroenie}
        onChange={bindChange(setStroenie)}
        disabled={false}/>
    </div>}
    {fields.kvartira && <div className={x.controlHolder}>
      <div className={x.label}>Квартира: </div>
      <input type="text" className={"form-control " + x.control}
             defaultValue={kvartira}
             onChange={bindChangeInput(setKvartira)}
             />
    </div>}
    <div className={x.controlHolder}>
      <button type="button"
              className="btn btn-primary mt-3 px-4 mx-auto"
              onClick={onSubmit}
              disabled={!canSubmit}>Продолжить</button>
    </div>
  </div>
}

export default StepMoreDetails;
