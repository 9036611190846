// noinspection JSIgnoredPromiseFromCall

import type { DependencyList } from 'react';
import { createRef, Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import { Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { useDocumentEvent } from './events';

export type CXArgument = string|Record<string,boolean>;
export const cx = (...args: CXArgument[]) : string => {
  return args.map((element) => {
    if (typeof element === 'string') return element;
    if (typeof element === 'object') {
      return Object.entries(element)
        .filter(([_, show]) => show)
        .map(([name]) => name)
        .join(' ');
    }
    console.warn(element, typeof element);
    throw new Error('Unknown type!');
  }).join(' ');
}

export const before = <F extends Function>(beforeCallback: () => any, target: F) : F => {
  const wrapped = (...args: any) => {
    beforeCallback();
    return target(...args);
  }
  return wrapped as unknown as F;
};

export const wait = (timeout: number) : Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, timeout));

export const useAsyncEffect = (effect: () => Promise<void>, deps?: DependencyList | undefined) =>
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

type UseDropdownClickOutput = [
  first: RefObject<HTMLElement | undefined>,
  second: boolean,
  third: Dispatch<SetStateAction<boolean>>
];
interface UseDropdownClickOptions {
  doNotOpen?: () => boolean,
  closeAnything?: boolean
}
export const useDropdownClick = (props?: UseDropdownClickOptions): UseDropdownClickOutput => {
  const ref = createRef<HTMLElement | undefined>();
  const [opened, setOpened] = useState<boolean>(false);
  const handler = (ev: MouseEvent) => {
    if (!ev.target || !ref.current) return;
    const target = ev.target as HTMLElement
    const isOpened = ref.current.contains(target);
    if (isOpened && props?.doNotOpen && props.doNotOpen()) return;
    if (props?.closeAnything && isOpened) {
      setOpened(x => !x);
      return;
    }
    setOpened(isOpened);
  };
  useDocumentEvent('click', handler);
  return [ref, opened, setOpened];
}


export type CancelPredicate = () => boolean;
export type OmitCancelPredicate<F> = F extends (x: CancelPredicate, ...args: infer P) => infer R ? (...args: P) => R : never;
export const useCancellable = (fn: Function, debug : boolean = false) => {
  const counter = useRef(0);
  return (...args: any[]) => {
    counter.current += 1;
    const innerCounter = counter.current;
    const cancel: CancelPredicate = () => {
      if (debug) console.warn('counter', counter.current, 'equals', innerCounter);
      return innerCounter !== counter.current;
    };
    return fn(cancel, ...args);
  };
};

// https://stackoverflow.com/a/39419171
export function assertUnreachable(_: never, message: string): never {
  throw new Error(message);
}

interface useLoadableOptions {
  setLoading?: (arg: boolean) => void,
  setError?: (arg: unknown) => void,
  consoleError?: boolean,
  onError?: (ex: unknown) => void,
}

export const wrapLoadable = <T,>(callback: () => Promise<T>, options?: useLoadableOptions) => {
  return new Promise<T>(async(resolve, reject) => {
    try {
      options?.setError?.call(null, undefined);
      options?.setLoading?.call(null, true);
      const data = await callback();
      resolve(data);
    } catch (ex) {
      if (options?.consoleError ?? true) console.error(ex);
      options?.setError?.call(null, ex);
      options?.onError?.call(null, ex);
      reject(ex);
    } finally {
      options?.setLoading?.call(null, false);
    }
  });
}

export const useLoadable = (callback: () => Promise<any>, options?: useLoadableOptions, deps?: DependencyList | undefined) =>
  useAsyncEffect(() => wrapLoadable(callback, options), deps ?? []);

interface useLocationCacheOptions {
  location?: Location,
  navigate?: NavigateFunction
}
type useLocationCacheOutput = [
  set: () => void,
  reset: () => void,
  effect: (callback: () => void) => void
]
export const useLocationCache = (key: string, options?: useLocationCacheOptions) : useLocationCacheOutput => {
  /* eslint-disable react-hooks/rules-of-hooks */
  const location = options?.location ?? useLocation();
  const navigate = options?.navigate ?? useNavigate();
  /* eslint-enable react-hooks/rules-of-hooks */
  const setCache = () => {
    const state = { [key]: true };
    navigate(location.pathname, {state});
  };
  const resetCache = () => {
    const state = { [key]: undefined };
    navigate(location.pathname, {state});
  };
  const useCacheEffect = (onBackEvent: () => void) => {
    useEffect(() => {
      const state = location.state as any;
      if (state?.[key]) onBackEvent();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
  }
  return [ setCache, resetCache, useCacheEffect ]
}

export const useTitle = (title: string) =>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { document.title = title; }, []);