import Show from './Show';
import Search from './Search';
import Landing from './Landing';
import Print from './Print';
import Business from './Search/Business';

export interface Route {
  title: string,
  location: string,
  element: JSX.Element,
  noHeader?: boolean
}

export const routes : Record<string, Route> = {
  print: {
    title: 'Печать',
    location: '/печать',
    element: <Print />,
    noHeader: true,
  },
  show: {
    title: 'Информация',
    location: '/инфо/:page/:id',
    element: <Show />
  },
  searchFull: {
    title: 'Поиск',
    location: '/поиск/:firmToken/:branchId',
    element: <Search />
  },
  searchName: {
    title: 'Поиск',
    location: '/поиск/:firmToken',
    element: <Search />
  },
  searchBase: {
    title: 'Поиск',
    location: '/поиск',
    element: <Search />
  },
  business: {
    title: 'Партнёрам',
    location: '/бизнес',
    element: <Business />,
  },
  landing: {
    title: 'Главная страница',
    location: '/',
    element: <Landing />
  },
}
