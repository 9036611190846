import { routes } from '../routes';
import UserPayment from './UserPayment';
import React, { Fragment } from 'react';

type SessionEntry<T> = { type: string, payload: T };
const SESSION_KEY = 'printTarget';

export const AllowedComponents = {
  UserPayment,
};

export const openPrintable = (type: string, render: JSX.Element) => {
  console.log('openPrintable', type, AllowedComponents);
  if (!(type in AllowedComponents)) throw new Error(`Тип "${type}" не объявлен в списке AllowedComponents!`);
  const target = window.open(routes.print.location, '_blank');
  if (!target) {
    console.warn('Не удалось создать новую вкладку!', target);
    return;
  }
  const entry = { type, payload: render.props };
  target.sessionStorage.setItem(SESSION_KEY, JSON.stringify(entry));
}

export const getRenderTarget = () : JSX.Element => {
  const entry = JSON.parse(window.sessionStorage.getItem(SESSION_KEY) ?? 'null') as SessionEntry<any>|undefined;
  if (!entry) return <Fragment />;
  if (!(entry.type in AllowedComponents)) {
    console.error(`Тип "${entry.type}" не объявлен в списке AllowedComponents!`);
    return <Fragment />
  }
  const type = entry.type as keyof typeof AllowedComponents;
  const props = entry.payload;
  // @ts-ignore
  return React.createElement(AllowedComponents[type], props, null);
}
