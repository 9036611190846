import { StringRecord } from '../types';

export interface SearchAddressData {
  region: string | undefined,
  city: string | undefined,
  street: string | undefined,
  house: string | undefined
}

export interface MoreDetailsOutput {
  korpus?: string,
  stroenie?: string,
  kvartira?: string
}

export interface MoreDetailsInput {
  verification_token: string,
  korpus?: StringRecord,
  stroenie?: StringRecord,
  kvartira?: true,
}

export enum SearchResponseType {
  SimpleAnswer = 0,
  MoreDetailsRequired = 1,
}
export enum SearchRequestType {
  InitialData = 0,
  ExtraDetails = 1
}

export type SearchResponse = SearchResponse_Simple|SearchResponse_MoreDetails;

export type SearchResponse_Simple = {
  type: SearchResponseType.SimpleAnswer,
  payload: ShortUserInfo[]
}

export type SearchResponse_MoreDetails = {
  type: SearchResponseType.MoreDetailsRequired,
  payload: MoreDetailsInput
}

export interface ShortUserInfo {
  permanent_id: string,
  company_title: string,
  address: string,
  client_id: string,
  sum?: string,
}

export interface BankInfo {
  name: string,
  bik: string,
  rs: string,
  ks: string,
  qr_url: string
}

export interface SubCompanyInfo {
  id: number,
  short_name: string,
  payment_name: string,
  phone?: string,
  email?: string,
  website?: string,
  contacts?: Record<string,string>,
  logo?: string,
  inn: string,
  kpp: string,
}

export interface FullUserInfo {
  uuid: string
  account_id: string,
  abonent_ls: string[],
  address: string,
  billing_period: string,
  prices: {
    tarif: string,
    payed: string,
    charges: string,
    debt: string,
    overpay: string,
    to_pay: string,
    raw_sum?: string
  },
  company: SubCompanyInfo
  banks: BankInfo[]
  print_frame?: string,
  files?: Record<string, string>
}

export interface ISettings {
  environment: {
    debug: boolean,
    production: boolean
  },
  captcha?: string
}
