/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { loadingAtom } from './store';
import { cx, useLoadable, useTitle } from '../../utils';
import { openPrintable } from '../Print/utils';
import { keyof } from 'ts-keyof';
import UserPayment from '../Print/UserPayment';
import { AnalyticType, loadFirm, sendAnalytic } from '../../api';
import { FullUserInfo } from '../../api/types';
import { TabBanks, TabMain, TabPayment, TabQR } from './utils';
import Dropdown from '../../components/Dropdown';
import { errorAtom } from '../Search/store';
import ErrorAlert from '../../components/ErrorAlert';
import { StaticFiles } from './constants';

interface CardFirmProps {
  uuid: string,
}

const useAnalytic = (callback: () => void) => {
  const refSended = useRef(false);
  const send = () => {
    // TODO: Запоминать о том что пользователь открыл QR на целые сутки?
    if (refSended.current) return;
    callback();
    refSended.current = true;
  }
  return { send };
}

const CardFirm = (props: CardFirmProps) => {

  const [error, setError] = useRecoilState(errorAtom);
  const [loading, setLoading] = useRecoilState(loadingAtom);
  const [card, setCard] = useState<FullUserInfo>();
  const [active,setActive] = useState<number>(1);
  const bankTitles = (card?.banks ?? []).map(bank => bank.name);
  const analytic = useAnalytic(() => sendAnalytic(AnalyticType.QR, props.uuid));

  useTitle(`Информация об абоненте`);
  useLoadable(async() => {
    setCard(await loadFirm(props.uuid));
  }, { setLoading, setError });

  if (error) return <ErrorAlert state={errorAtom} />;
  if (loading || !card) return <></>;

  const tabGroups : [string,() => JSX.Element][] = [
    ['ДШК / QR-код', () => <TabQR card={card} onInit={analytic.send} />],
    ['Информация', () => <TabMain card={card} />],
    ['Платежные реквизиты', () => <TabBanks card={card} /> ],
    ['История оплаты', () => <TabPayment card={card} />],
  ]

  const onPrint = (printBankIndex: number) => {
    sendAnalytic(AnalyticType.Print, props.uuid);
    openPrintable(keyof({UserPayment}), <UserPayment card={card} selectedBank={printBankIndex} />);
  }

  const files = card.files ?? {};

  return <div>
    {card.company.logo && <div className="d-flex justify-content-center mb-3">
        <img src={card.company.logo} alt="" style={{maxWidth: '100%'}} />
    </div>}

    <ul className="nav nav-pills mb-3">
      {tabGroups.map((group, index) => (
        <li className="nav-item" key={index}>
          <a className={cx("nav-link", {active: index === active})} href="#"
             onClick={(e) => { e.preventDefault(); setActive(index); }}
          >{group[0]}</a>
        </li>
      ))}
    </ul>
    {tabGroups[active][1]()}

    <div>
      {StaticFiles.PayInstructions in files && <Fragment>
          <a href={files[StaticFiles.PayInstructions]} target="_blank" rel="noreferrer"
             className="btn btn-warning mx-2" style={{"fontSize": "18px"}}>Инструкции по оплате</a>
      </Fragment>}
      {bankTitles.length === 1
        ? <button
        onClick={onPrint.bind(null, 0)}
        className="btn btn-warning mx-2 font-button">
        Печать квитанции
      </button> : <Dropdown title="Печать квитанции"
                            filled={false}
                            width="700px"
                            headerClass="btn btn-warning mx-2 font-button">
          <ul className="dropdown-menu show">
            {bankTitles.map((title, index) => <li key={index}>
              <a href="#"
                 onClick={(ev) => {ev.preventDefault(); onPrint(index)}}
                 className="dropdown-item">
                {index+1}. {title}
              </a>
            </li>)}
          </ul>
      </Dropdown>}
    </div>

  </div>

}

export default CardFirm;
