import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { RecoilRoot } from "recoil";
import Header from '../components/Header';
import { routes, Route as IRoute } from './routes';

const withHeader = (child: JSX.Element) =>
  (
    <div className="container my-2">
      <Header/>
      {child}
    </div>
  )

const mapRoute = ([key, route] : [key: string, route: IRoute]) => {
  let element = route.element;
  if (!route.noHeader) element = withHeader(element);
  const location = encodeURI(route.location);
  return <Route key={key} path={location} element={element} />;
}

const App = () => (
  <RecoilRoot>
    <Router>
      <Routes>
        {Object.entries(routes).map(mapRoute)}
      </Routes>
    </Router>
  </RecoilRoot>
)

export default App;
