
type VoidHandler = () => void;

interface CardTryAgainProps {
  onRetry: VoidHandler,
  onReset: VoidHandler
}

const StepTryAgain = ({onRetry, onReset} : CardTryAgainProps) => {
  return <div className="d-flex justify-content-center">
    <div className="btn btn-primary me-2" onClick={onRetry}>Повторить попытку</div>
    <div className="btn btn-danger" onClick={onReset}>Начать заново</div>
  </div>
}

export default StepTryAgain