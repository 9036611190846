import { Fragment, useEffect, useState } from 'react';
import ProgressBar from '../../components/ProgressBar';
import { Link, useParams } from 'react-router-dom';
import { routes } from '../routes';
import { getFirmByToken } from '../../api';
import { assertUnreachable } from '../../utils';
import { Alert, IAlertProps } from '../../components/Alert';
export const FirmBlock = ({token}: {token: string|undefined}) => {

  const [name, setName] = useState('');
  const [alert, setAlert] = useState<IAlertProps>();
  const [loading, setLoading] = useState(true);
  const { branchId } = useParams();

  useEffect(() => {
    const onLoad = async() => {
      if (!token) return;
      try {
        setName('');
        setLoading(true);
        setAlert(undefined);
        const res = await getFirmByToken(token);
        if ('message' in res) {
          setAlert({type: 'warning', message: res.message});
        } else if ('branches' in res) {
          const name = res.branches.find(x => x.id.toString() === branchId)?.short_name;
          if (branchId && !name) console.warn('Не найден филиал фирмы!', branchId);
          setName(name ?? res.branches[0]?.short_name);
        } else assertUnreachable(res, 'Получен недопустимый ответ!');
      } catch (ex) {
        setAlert({type: 'danger', message: 'Не удалось получить название фирмы!'});
        console.error(ex);
      } finally {
        setLoading(false);
      }
    }
    onLoad().catch(console.error)
  }, [token, branchId]);

  if (!token) return <Fragment />;

  if (loading) return <div>
    <ProgressBar />
  </div>;

  if (alert) return <Alert {...alert} />;

  return <div>
    <h4 className="mb-4 position-relative">
      <span className="text-muted">Фирма:</span>
      <span className="ms-3">{name}</span>
      <Link style={
        {textDecoration: 'none', fontSize: '1.2em', position: 'absolute', bottom: '0'}}
            title="Удалить фирму" to={routes.searchBase.location} className="p-1 text-danger">
        ×
      </Link>
    </h4>
  </div>
}
