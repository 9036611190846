import { FormEvent, useId } from 'react';

export interface CheckboxProps {
  onChange?: (state: boolean) => void,
  defaultChecked?: boolean,
  className?: string,
  title: string
}

const Checkbox = (props: CheckboxProps) => {
  const id = useId();
  const { defaultChecked, title } = props;
  const { className = ''} = props;

  const onChange = (ev: FormEvent<HTMLInputElement>) => {
    const target = ev.target as HTMLInputElement;
    if (!props.onChange) return;
    props.onChange(target.checked);
  }

  return <div className={"form-check " + className}>
    <input className="form-check-input"
           type="checkbox"
           defaultChecked={defaultChecked}
           id={id}
           onChange={onChange} />
    <label className="form-check-label" htmlFor={id}>
      {title}
    </label>
  </div>
}

export default Checkbox;