import { atom } from 'recoil';
import { ISettings, MoreDetailsInput, MoreDetailsOutput, SearchAddressData, ShortUserInfo } from '../../api/types';

export const loadingAtom = atom({
  key: 'search_loading',
  default: false,
});

export const errorAtom = atom<unknown>({
  key: 'search_error',
  default: undefined
});

export const captchaAtom = atom({
  key: 'search_captcha', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
});

export const addressAtom = atom<SearchAddressData>({
  key: 'search_address',
  default: undefined
})

export const codeAtom = atom<string>({
  key: 'search_code',
  default: undefined
})

export const moreDetailsOutputAtom = atom<MoreDetailsOutput>({
  key: 'search_input_extra',
  default: undefined
});

export const moreDetailsInputAtom = atom<MoreDetailsInput>({
  key: 'search_output_extra',
  default: undefined
})

export const userInfoAtom = atom<ShortUserInfo[]>({
  key: 'search_user_info',
  default: undefined
});

export const clientSettingsAtom = atom<ISettings>({
  key: 'client_settings',
  default: undefined
});
