import { FullUserInfo } from '../../api/types';
import React, { Fragment, useEffect, useState } from 'react';
import { TabGroup } from '../../components/TabGroup';
import ToPaySum from '../../components/ToPaySum';

type GroupBody = React.ReactNode;

export interface DisplayGroup {
  title: string,
  fields: [string,GroupBody][]
}

export const renderGroups = (groups : DisplayGroup[]|undefined) => {
  if (!groups) return <Fragment />
  return <table className="table table-bordered table-striped table-hover">
    {groups.map((group,grIndex) => <Fragment key={grIndex}>
      <thead>
      <tr className="table-primary">
        <th colSpan={2}>{group.title}</th>
      </tr>
      </thead>
      <tbody>
      {group.fields.map((field, index) => <tr key={index}>
        <td>{field[0]}</td>
        <td>{field[1]}</td>
      </tr>)}
      </tbody>
    </Fragment>)}
  </table>
}


export const TabQR = (props: { card: FullUserInfo|undefined, onInit: () => void }) => {

  const banks = props.card?.banks ?? [];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    props.onInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (banks.length === 0) return <Fragment/>;
  if (banks.length === 1) return <div className="mb-4 d-flex justify-content-center">
    <img src={banks[0].qr_url} alt="" width={384} height={384}/>
  </div>;

  return <div>
    <TabGroup items={banks.map(x => x.name)} onSelection={setIndex} selected={index} />
    <div className="mb-4 d-flex justify-content-center">
      <img src={banks[index].qr_url} alt="" width={384} height={384}/>
    </div>
  </div>
}

export const TabBanks = ({card} : {card?: FullUserInfo}) => {
  const [bankIndex, setBankIndex] = useState(0);
  const bank = card?.banks[bankIndex];
  const bankTitles = (card?.banks ?? []).map(bank => bank.name);
  const paymentDetails : DisplayGroup[] = [
    {
      title: 'Получатель',
      fields: [
        ['Название', card?.company.payment_name],
        ['ИНН', card?.company.inn],
        ['КПП', card?.company.kpp],
        ['Счёт', bank?.rs],
      ]
    },
    {
      title: 'Банк получателя',
      fields: [
        ['Название', bank?.name],
        ['БИК', bank?.bik],
        ['Счёт', bank?.ks],
      ]
    }
  ]
  return <div>
    {bankTitles.length > 1 && <TabGroup items={bankTitles} selected={bankIndex} onSelection={setBankIndex} />}
    {renderGroups(paymentDetails)}
  </div>;
}

export const TabMain = ({card} : {card?: FullUserInfo}) => {
  if (!card) return <Fragment />;
  const main : DisplayGroup[] = [
    {
      title: 'Ваша домофонная организация',
      fields: [
        ['Название', card.company.payment_name],
        ['Телефон', card.company.phone],
        ['E-Mail', card.company.email],
        ['Веб-сайт', card.company.website],
      ]
    },
    {
      title: 'Ваш счёт',
      fields: [
        ['Номер лицевого счёта', card.abonent_ls.join(', ')],
        ['Адрес', card.address],
        ['Расчётный период', card.billing_period],
        ['К оплате', <ToPaySum card={card} />],
      ]
    }
  ]
  return renderGroups(main);
}

export const TabPayment = ({card} : {card?: FullUserInfo}) => {
  if (!card) return <Fragment />;
  const paymentHistory : DisplayGroup[] = [
    {
      title: 'История оплаты',
      fields: [
        ['Тариф', card.prices.tarif],
        ['Расчётный период', card.billing_period],
        ['',''],
        ['Оплачено', card.prices.payed],
        ['Начислено', card.prices.charges],
        (card.prices.debt?.length > 0
          ? ['Долг на начало периода', card.prices.debt]
          : ['Переплата на начало периода', card.prices.overpay]),
        ['',''],
        ['К оплате', <ToPaySum card={card} />],
      ]
    },
  ]
  return renderGroups(paymentHistory);
}
