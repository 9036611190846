/* eslint-disable jsx-a11y/anchor-is-valid */
import x from './Dropdown.module.scss';
import { FC, ReactNode } from 'react';
import { cx, useDropdownClick } from '../utils';

interface DropdownProps {
  title: string
  children: ReactNode,
  headerClass?: string
  width: string,
  filled?: boolean
}

const Dropdown : FC<DropdownProps> = (props) => {
  const [ refRoot, opened ] = useDropdownClick({closeAnything: true});
  const filled = (props.filled ?? true) ? x.filled : '';
  return <div className={x.root} ref={refRoot as any}>
    <a href="#"
       className={cx(props.headerClass ?? '', "dropdown-toggle")}
       onClick={(e) => { e.preventDefault() }}>
      {props.title}
      <span className={x.icon} />
    </a>
    <div className={cx(x.dropdown, filled, {[x.hidden]: !opened})} style={{"width": props.width}}>
      {props.children}
    </div>
  </div>
}

export default Dropdown;
