/* eslint-disable react-hooks/exhaustive-deps */
// noinspection JSIgnoredPromiseFromCall

import x from './index.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { clientSettingsAtom, errorAtom, loadingAtom } from './store';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import SearchAddress from './pages/SearchAddress';
import ErrorAlert from '../../components/ErrorAlert';
import SearchCode from './pages/SearchCode';
import { TabGroup } from '../../components/TabGroup';
import { FirmBlock } from './FirmBlock';
import { cx } from '../../utils';
import { getClientSettings } from '../../api';

const TABS : [string,JSX.Element][] = [
  ['Адрес', <SearchAddress />],
  ['Лицевой счёт', <SearchCode />]
]

const useLoadSettings = () => {
  const [,setSettings] = useRecoilState(clientSettingsAtom);
  const [,setLoading] = useRecoilState(loadingAtom);
  const [,setError] = useRecoilState(errorAtom);
  const onLoad = async() => {
    try {
      setLoading(true);
      // throw new Error('Something!');
      setSettings(await getClientSettings());
    } catch (ex) {
      setError(ex);
      console.error("Не удалось загрузить настройки приложения!");
      console.error(ex);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => { onLoad(); }, []);
}

const Search = () => {

  const params = useParams();
  const [ loading ] = useRecoilState(loadingAtom);
  const [tab, setTab] = useState(0);

  const token = params.firmToken;

  const body = useMemo<JSX.Element>(() => {
      return TABS[tab][1];
    }, [token, tab]);

  useLoadSettings();

  return <div className={cx(x.controls, "search_controls")}>
    <ErrorAlert state={errorAtom} dismissible={true} />
    <div className={x.background}>
      <div className={loading ? 'hide' : ''}>
        <TabGroup items={TABS.map((x) => x[0])} selected={tab} onSelection={setTab} />
        <FirmBlock token={token} />
        {body}
      </div>
      {loading && <div>
          <div className="progress">
              <div className="progress-bar progress-bar-striped progress-bar-animated" style={{'width': '100%'}}/>
          </div>
          <h5 className="text-center mt-3">Ожидание ответа сервера...</h5>
      </div>}
    </div>
  </div>
}

export default Search;
